<template>
  <div class="content">
    <div class="flex">
      <div>
        <Menu />
      </div>
      <div class="w-full relative">
        <div
          class="flex items-center justify-between px-6 pt-6 pb-2 border-b-2 border-nColorTextInput"
        >
          <div class="flex items-center gap-2">
            <div class="font-InterExtraBold text-3xl text-nColorOrange">
              Terms And Conditions
            </div>
          </div>
          <div id="menu-account">
            <button
              class="font-ZuumeMedium px-4 py-1 rounded-3xl text-xl border border-nColorYellow text-nColorYellow bg-nColorTextInput"
              @click.prevent="signOut()"
            >
              Sign out
            </button>
          </div>
        </div>
        <div class="bg-nColorTextInput rounded-3xl text-white p-6 m-4">
          <h2 class="text-2xl font-bold">Acceptance of Terms</h2>
          <p class="text-nColorLightGray">
            By accessing and using playda.io, you accept and agree to be bound
            by the terms and provision of this agreement. In addition, when
            using this app's particular services, you shall be subject to any
            posted guidelines or rules applicable to such services.
          </p>
          <h2 class="text-2xl font-bold mt-6">Modification of Terms</h2>
          <p class="text-nColorLightGray">
            playda.io reserves the right to change the terms, conditions, and
            notices under which the app is offered, including but not limited to
            the charges associated with the use of the playda.io.
          </p>
          <h2 class="text-2xl font-bold mt-6">User Conduct</h2>
          <p class="text-nColorLightGray">
            You are responsible for all your app activity in connection with the
            Services and accessing the app. playda.io is not responsible for the
            content of any user's communications and is not liable for any
            illegal conduct or speech by its users.
          </p>
          <h2 class="text-2xl font-bold mt-6">Warranty Disclaimer</h2>
          <p class="text-nColorLightGray">
            Your use of playda.io is at your sole risk. The app is provided on
            an "as is" and "as available" basis. playda.io expressly disclaims
            all warranties of any kind, whether express or implied.
          </p>
          <h2 class="text-2xl font-bold">Limitation of Liability</h2>
          <p class="text-nColorLightGray">
            playda.io shall not be liable for any direct, indirect, incidental,
            special, consequential, or exemplary damages resulting from your use
            or inability to use the app or for the cost of procurement of
            substitute goods and services.
          </p>
          <h2 class="text-2xl font-bold mt-6">Cancellation Policy</h2>
          <p class="text-nColorLightGray">
            Orders can be canceled within 24 hours of placing the order. To
            cancel, please contact us at support@playda.io. Subscriptions may be
            canceled at any time. The cancellation will take effect at the end
            of the current billing cycle.
          </p>
          <h2 class="text-2xl font-bold mt-6">Refund Policy</h2>
          <p class="text-nColorLightGray">
            Refunds are available for purchases made on playda.io if the request
            is made within 14 days of the transaction. Due to the nature of
            digital products, refunds will be considered only if the product has
            not been downloaded or used. For any customised services, refunds
            will be granted if the service has not been provided or if work has
            not commenced. Certain sale items or special orders may be exempt
            from this policy and will be noted as such at the time of purchase.
          </p>
          <h2 class="text-2xl font-bold mt-6">Processing Refunds</h2>
          <p class="text-nColorLightGray">
            To request a refund, please contact us at support@playda.io with
            your order details. All refund requests are subject to review and
            approval by playda.io. Refunds will be processed to the original
            method of payment within 7-10 business days of approval.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Dashboard/DesktopMenu.vue";

export default {
  name: "TermsAndConditions",
  components: { Menu },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    signOut() {
      this.$store.dispatch("signOut");
      this.$router.push({
        name: "Login",
      });
    },
  },
};
</script>

<style scoped lang="postcss">
.content {
  height: 100dvh;
}
</style>
